<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem v-if="type === 'detail'">订单查看</BreadcrumbItem>
        <BreadcrumbItem v-if="type === 'examine'">订单审核</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              {{ info.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="客户">
              {{ info.orderCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商家">
              {{ info.producerCorporationName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单类别">
              {{ info.orderTypeValue }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请时间">
              {{ info.createTime ? tsFormat(info.createTime) : "" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="审核状态">
              {{ info.orderStatusValue }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="原订单号">
              <a>
                <span @click="onCheckPurchaseOrder(info.orderPurchaseSn)">
                  {{ info.orderPurchaseSn }}
                </span>
              </a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">收货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              {{ info.consigneeRealName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              {{ info.consigneeTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              {{ info.consigneeAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商品信息</div>
      <a-table
        class="commodity__table"
        :data-source="commodityData"
        :columns="commodityColumns"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="goodsSn" slot-scope="text, record">
          <span v-if="record.skuCode === '合计：'"></span>
          <span v-else>{{ text ? text : "-" }}</span>
        </template>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">退款信息</div>
      <a-table
        class="refunded__table"
        :columns="refundedColumns"
        :data-source="refundedData"
        :loading="refundedLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="huifuSeqId" slot-scope="text">
          {{ text || "-" }}
        </template>
        <template slot="paymentTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">合同信息</div>
      <a-table
        class="contract__table"
        :columns="contractColumns"
        :data-source="contractData"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="signTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            v-if="record.contractFile && record.contractFile.fileUrl"
            :href="imageBaseUrl + record.contractFile.fileUrl"
            target="_blank"
          >
            预览
          </a>
        </template>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">
        物流信息
      </div>
      <a-collapse default-active-key="0" style="margin-top: 20px">
        <a-collapse-panel
          v-for="(item, index) in deliverList"
          :key="index"
          :header="`第${changeNumToString(index + 1)}批次明细`"
        >
          <div slot="extra">
            发货时间：{{ tsFormat(item.logisticsSendTime) }}
          </div>
          <a-form class="advanced-search-form">
            <a-row>
              <a-col :span="8">
                <a-form-item label="物流电话">
                  {{ item.logisticsTel || "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="物流公司">
                  {{ item.logisticsName || "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="物流单号">
                  {{ item.logisticsSn || "-" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="发货清单">
                  <a-table
                    :columns="inventoryColumns"
                    :data-source="calculateDetail(item.detailVoList)"
                    :loading="loading"
                    :pagination="false"
                    rowKey="skuId"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <div v-if="item.receiveBill && item.receiveBill.length > 0">
              <div v-for="ele in item.receiveBill" :key="ele.receivingId">
                <a-row>
                  <a-col :span="8">
                    <a-form-item label="收货时间">
                      {{ ele.signTime ? tsFormat(ele.signTime) : "-" }}
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="发票信息">
                      <a>查看</a>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24">
                    <a-form-item label="收货清单">
                      <a-table
                        :columns="inventoryColumns"
                        :data-source="calculateDetail(ele.deliverVoList)"
                        :loading="loading"
                        :pagination="false"
                        rowKey="skuId"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-form>
        </a-collapse-panel>
      </a-collapse>
    </section>
    <section class="card__container">
      <div class="container__title between">
        流程日志
        <a @click="onShowProcessImg" class="process_img">点击查看流程图</a>
        <div v-viewer class="images">
          <img
            v-if="
              info.purchaserCorporationDistributorTypeValue === '货栈' ||
                info.purchaserCorporationDistributorTypeValue === '服务中心'
            "
            src="../../assets/imgs/process/returnedOrder.png"
            :data-source="
              require('../../assets/imgs/process/returnedOrder.png')
            "
            alt="流程图"
          />
          <img
            v-else
            src="../../assets/imgs/process/end-factory_return.png"
            :data-source="
              require('../../assets/imgs/process/end-factory_return.png')
            "
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ tsFormat(text) }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text || " - " }}
        </template>
      </a-table>
    </section>
    <section
      class="card__container"
      :class="type === 'examine' ? 'high__bottom' : 'low__bottom'"
    >
      <div class="container__title between">
        <div class="log__title">
          退款流程日志
          <a-button
            type="primary"
            class="btn--reload"
            @click="onReload"
            v-if="
              info.orderStatusValue === '未通过' &&
                personnelType.includes(info.lastApprovalPersonnelType)
            "
          >
            重新发起审核
          </a-button>
        </div>
        <a @click="onShowPayProcessImg" class="process_img">点击查看流程图</a>
        <div v-viewer class="pay_images">
          <img
            v-if="
              info.purchaserCorporationDistributorTypeValue === '货栈' ||
                info.purchaserCorporationDistributorTypeValue === '服务中心'
            "
            src="../../assets/imgs/process/operation-service.png"
            :data-source="
              require('../../assets/imgs/process/operation-service.png')
            "
            alt="流程图"
          />
          <img
            v-else
            src="../../assets/imgs/process/operation-end.png"
            :data-source="
              require('../../assets/imgs/process/operation-end.png')
            "
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="payLogColumns"
        :data-source="payLogData"
        :loading="payLogLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="operatorTime" slot-scope="text">
          {{ tsFormat(text) }}
        </template>
        <template slot="orderApprovalMemo" slot-scope="text">
          {{ text || " - " }}
        </template>
        <template slot="paymentApprovalMemo" slot-scope="text">
          {{ text || " - " }}
        </template>
      </a-table>
    </section>
    <section class="examine__container" v-if="type === 'examine'">
      <a-form class="examine__form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="订单审核意见">
              <a-textarea
                v-model="approvalMemo"
                :auto-size="{ minRows: 1, maxRows: 2 }"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="付款审核意见">
              <a-textarea
                v-model="paymentApprovalMemo"
                :auto-size="{ minRows: 1, maxRows: 2 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="operation">
          <div class="tip">
            <a-icon type="info-circle" />
            审核不通过，两个审核意见必填；付款流程结束后，订单审核意见将会显示到订单流程日志意见列表
          </div>
          <a-space>
            <a-button @click="onUnPass">不通过</a-button>
            <a-button type="primary" @click="onPass">通过</a-button>
          </a-space>
        </div>
      </a-form>
    </section>

    <a-modal
      :visible="payModal"
      title="确认付款金额"
      width="40%"
      @cancel="onCancelModal"
      @ok="onConfirmPay"
      :confirm-loading="confirmLoading"
    >
      <div class="pay__num">
        实付¥
        <div v-if="info.instantBill">
          {{ info.instantBill.actualPayment }}
        </div>
      </div>
      <div class="total__num">
        订单金额：¥
        <div v-if="info.instantBill">
          {{ info.instantBill.totalPrice }}
        </div>
      </div>
      <a-divider style="margin-bottom: 12px" />
      <div class="pay__item">
        <div class="pay__item--logo">
          <img src="../../assets/order/pay.png" />
          余额支付
        </div>
        <a-radio-group :default-value="1">
          <a-radio :value="1" />
        </a-radio-group>
      </div>
      <a-divider style="margin-top: 12px" />
    </a-modal>

    <!--  支付结果弹窗  -->
    <a-modal
      :visible="payResultModal"
      width="50%"
      title="支付结果"
      @cancel="onConfirmPayResult"
    >
      <template slot="footer">
        <a-button type="primary" @click="onConfirmPayResult">
          <span v-if="payResult">确认（{{ second }}s）</span>
          <span v-else>确认</span>
        </a-button>
      </template>
      <div class="logo__container">
        <img src="../../assets/order/icon_success.png" v-if="payResult" />
        <img src="../../assets/order/icon_fail.png" v-if="!payResult" />
        <span v-if="payResult">支付成功</span>
        <span v-if="!payResult">支付失败</span>
      </div>
      <a-divider />
      <a-form>
        <a-row>
          <a-col :span="12">
            <a-form-item label="交易时间">
              {{ payInfo.paymentTime ? tsFormat(payInfo.paymentTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="交易单号">
              <div style="word-break: break-word">{{ payInfo.billId }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="订单金额">
              ¥{{ payInfo.paymentAmount }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="实付款">
              ¥{{ payInfo.actualPayment }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="!payResult">
          <a-col :span="24">
            <a-form-item label="失败原因">
              {{ errorMsg }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>

    <div class="top__back__container" v-if="showTop" @click="onScrollTop">
      <img src="../../assets/order/icon-top.png" alt="icon" />
      回到顶部
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import {
  fetchOrderDetail,
  fetchOrderLog,
  fetchPayLogList,
  fetchPayResult,
  ordersOfflinePaymentPost,
  payExamine,
  reissueExamine
} from "@/services/OrderManageService";
import { tsFormat } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import { changeNumToString } from "@/components/utils";
import {
  fetchReceiveBillBySaleBill,
  fetchSaleBillByOrderSn
} from "@/services/OrderService";

const commodityColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "25%"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn",
    scopedSlots: { customRender: "goodsSn" },
    width: "15%"
  },
  {
    title: "单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "数量",
    dataIndex: "purchaseQuantity",
    width: "15%"
  },
  {
    title: "总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "15%"
  }
];
const contractColumns = [
  {
    title: "合同名称",
    dataIndex: "contractName",
    width: "20%"
  },
  {
    title: "签署方1",
    dataIndex: "partyFirst",
    width: "20%"
  },
  {
    title: "签署方2",
    dataIndex: "partySecond",
    width: "20%"
  },
  {
    title: "签订时间",
    dataIndex: "signTime",
    scopedSlots: { customRender: "signTime" },
    width: "20%"
  },
  {
    title: "状态",
    dataIndex: "contractStatus",
    width: "10%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];
const inventoryColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "40%"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品序列号",
    dataIndex: "goodsSn",
    width: "15%"
  },
  {
    title: "单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "quantity",
    width: "15%"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "15%"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "5%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];
const payLogColumns = [
  {
    title: "执行人",
    dataIndex: "operatorName",
    width: "10%"
  },
  {
    title: "部门名称",
    dataIndex: "personnelTypeName",
    width: "10%"
  },
  {
    title: "操作时间",
    dataIndex: "operatorTime",
    scopedSlots: { customRender: "operatorTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "10%"
  },
  {
    title: "付款审核意见",
    dataIndex: "paymentApprovalMemo",
    scopedSlots: { customRender: "paymentApprovalMemo" },
    width: "25%"
  },
  {
    title: "订单审核意见",
    dataIndex: "orderApprovalMemo",
    scopedSlots: { customRender: "orderApprovalMemo" },
    width: "25%"
  }
];

const refundedColumns = [
  {
    title: "流水号",
    dataIndex: "huifuSeqId",
    scopedSlots: { customRender: "huifuSeqId" },
    width: "15%"
  },
  {
    title: "退款机构",
    dataIndex: "paymentCorporationName",
    width: "20%"
  },
  {
    title: "收款机构",
    dataIndex: "receiveCorporationName",
    width: "20%"
  },
  {
    title: "应退款金额（元）",
    dataIndex: "actualPayment",
    width: "10%"
  },
  // {
  //   title: "实际退款金额（元）",
  //   dataIndex: "accountPaid",
  //   width: "10%"
  // },
  {
    title: "退款时间",
    dataIndex: "paymentTime",
    scopedSlots: { customRender: "paymentTime" },
    width: "15%"
  },
  {
    title: "状态",
    dataIndex: "paymentStatus",
    width: "10%"
  }
];

export default {
  name: "RefundReviewDetail",
  components: {
    Breadcrumb,
    BreadcrumbItem
  },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      commodityData: [],
      commodityColumns,
      loading: false,
      contractColumns,
      contractData: [],
      showTop: false,
      info: {},
      tsFormat,
      imageBaseUrl,
      deliverList: [],
      changeNumToString,
      inventoryColumns,
      logColumns,
      logData: [],
      logLoading: false,
      payLogColumns,
      payLogLoading: false,
      payLogData: [],
      approvalMemo: "",
      paymentApprovalMemo: "",
      refundedColumns,
      refundedData: [],
      refundedLoading: false,
      payModal: false,
      payResultModal: false,
      payResult: false,
      second: 5,
      confirmLoading: false,
      payInfo: {},
      errorMsg: "",
      personnelType: [],
      fromRouterName: "",
      storeRouterName: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  watch: {
    second: {
      handler(newVal) {
        if (newVal === 0) {
          this.$router.push({ name: "RefundReview" });
        }
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    if (localStorage.getItem("personnelType")) {
      this.personnelType = JSON.parse(localStorage.getItem("personnelType"));
    }
    this.loadDetail();
    this.loadDeliverInfo();
    this.loadLogList();
    this.loadPayLogList();
    this.loadPayBill();
    const that = this;
    window.onscroll = function() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        that.showTop = true;
      } else {
        that.showTop = false;
      }
    };
  },
  methods: {
    // 回到顶部
    onScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    // 查看付款流程图
    onShowPayProcessImg() {
      const viewer = this.$el.querySelector(".pay_images").$viewer;
      viewer.show();
    },
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: "payment-approval"
      };
      fetchOrderDetail(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          this.commodityData = this.calculate(this.info.instantOrderSkus);
          if (this.info.contracts && this.info.contracts.length > 0) {
            this.contractData = this.info.contracts;
          }
        }
      });
    },
    // 加载发货单
    loadDeliverInfo() {
      this.loading = true;
      fetchSaleBillByOrderSn(this.orderSn).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.deliverList = resp.data.data;
          if (this.deliverList && this.deliverList.length > 0) {
            this.deliverList.forEach(item => {
              this.loadReceiveBill(item.consignmentId);
            });
          }
        }
      });
    },
    // 获取收货单
    loadReceiveBill(consignmentId) {
      fetchReceiveBillBySaleBill(consignmentId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          let index = this.deliverList.findIndex(
            ele => ele.consignmentId === consignmentId
          );
          this.deliverList[index].receiveBill = data;
          this.deliverList = [...this.deliverList];
        }
      });
    },
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 加载付款流程日志
    loadPayLogList() {
      this.payLogLoading = true;
      fetchPayLogList(this.orderSn).then(resp => {
        this.payLogLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.payLogData = resp.data.data;
          if (this.payLogData.length > 0) {
            const item = this.payLogData[this.payLogData.length - 1];
            this.approvalMemo = item.orderApprovalMemo;
            this.paymentApprovalMemo = item.paymentApprovalMemo;
          }
        }
      });
    },
    // 计算
    calculate(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let totalPrice = 0;
        let num = 0;
        let allPrice = 0;
        list.forEach(item => {
          num = num + parseInt(item.purchaseQuantity);
          totalPrice = totalPrice + parseFloat(item.skuFactoryTotalPrice);
          allPrice = allPrice + parseFloat(item.skuPurchaseTotalPrice);
        });
        const params = {
          skuCode: "合计：",
          purchaseQuantity: num,
          skuFactoryTotalPrice: totalPrice.toFixed(2),
          skuPurchaseTotalPrice: allPrice.toFixed(2)
        };
        arr.push(params);
      }
      return arr;
    },
    // 计算清单合计
    calculateDetail(list) {
      let arr = [...list];
      if (list && list.length > 0) {
        let num = 0;
        let allPrice = 0;
        list.forEach(item => {
          num = num + parseInt(item.quantity);
          allPrice = allPrice + parseFloat(item.skuFactoryPrice);
        });
        const params = {
          skuName: "合计：",
          quantity: num,
          skuFactoryPrice: allPrice.toFixed(2)
        };
        arr.push(params);
      }
      return arr;
    },
    // 不通过
    onUnPass() {
      if (!this.approvalMemo) {
        this.$message.info("请输入订单审核意见");
        return;
      }
      if (!this.paymentApprovalMemo) {
        this.$message.info("请输入付款审核意见");
        return;
      }
      const params = {
        orderSn: this.orderSn,
        isAdopt: false,
        approvalMemo: this.approvalMemo,
        paymentApprovalMemo: this.paymentApprovalMemo
      };
      payExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("提交成功");
          if (this.storeRouterName === "ToDoTask") {
            this.$router.replace({
              name: "RefundReviewDetail",
              params: { orderSn: this.orderSn, type: "detail" }
            });
          } else {
            this.$router.push({ name: "RefundReview" });
          }
        }
      });
    },
    // 通过
    onPass() {
      // if (this.personnelType !== "accounting_second_dept") {
      //
      // } else {
      //   this.payModal = true;
      // }
      const params = {
        orderSn: this.orderSn,
        isAdopt: true,
        approvalMemo: this.approvalMemo,
        paymentApprovalMemo: this.paymentApprovalMemo
      };
      payExamine(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          if (
            JSON.parse(localStorage.getItem("personnelType")).indexOf(
              "OperationHeadquartersAccountingSecondDept"
            ) !== -1
          ) {
            ordersOfflinePaymentPost({
              orderSn: params.orderSn,
              type: "teller"
            }).then(res => {
              if (res.data.code === "SUCCESS") {
                // if (res.data.data) {
                //   this.$message.success("提交成功");
                //   this.$router.push({
                //     name: "CashierDetail",
                //     query: {
                //       type: "pay",
                //       rn: res.data.data,
                //       relId: params.orderSn
                //     }
                //   });
                // } else {
                //   this.$message.success("提交成功");
                //   if (this.storeRouterName === "ToDoTask") {
                //     this.$router.replace({
                //       name: "RefundReviewDetail",
                //       params: { orderSn: this.orderSn, type: "detail" }
                //     });
                //   } else {
                //     this.$router.push({ name: "RefundReview" });
                //   }
                // }
                this.$message.success("提交成功");
                if (this.storeRouterName === "ToDoTask") {
                  this.$router.replace({
                    name: "RefundReviewDetail",
                    params: { orderSn: this.orderSn, type: "detail" }
                  });
                } else {
                  this.$router.push({ name: "RefundReview" });
                }
              }
            });
          } else {
            this.$message.success("提交成功");
            if (this.storeRouterName === "ToDoTask") {
              this.$router.replace({
                name: "RefundReviewDetail",
                params: { orderSn: this.orderSn, type: "detail" }
              });
            } else {
              this.$router.push({ name: "RefundReview" });
            }
          }
        }
      });
    },
    // 重新发起审核
    onReload() {
      reissueExamine(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("重新发起成功");
          if (this.storeRouterName === "ToDoTask") {
            this.$router.push({ name: "ToDoTask" });
          } else {
            this.$router.push({ name: "RefundReview" });
          }
        }
      });
    },
    // 查付款单
    loadPayBill() {
      fetchPayResult(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            this.refundedData = data;
          }
        }
      });
    },
    // 确认支付结果
    onConfirmPayResult() {
      this.$router.push({ name: "RefundReview" });
    },
    // 取消打开支付弹窗
    onCancelModal() {
      this.payModal = false;
    },
    // 确认支付弹窗
    onConfirmPay() {
      this.confirmLoading = true;
      const params = {
        orderSn: this.orderSn,
        isAdopt: true,
        approvalMemo: this.approvalMemo,
        paymentApprovalMemo: this.paymentApprovalMemo
      };
      payExamine(params)
        .then(resp => {
          this.confirmLoading = false;
          if (resp.data.code === "SUCCESS") {
            this.payModal = false;
            this.payResult = true;
            this.payResultModal = true;
            this.loadPayDetail();
          } else {
            this.payModal = false;
            this.payResult = false;
            this.payResultModal = true;
            this.loadPayDetail();
            this.errorMsg = resp.data.errorMsg;
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    // 加载支付详情
    loadPayDetail() {
      fetchPayResult(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            let item = data.find(
              ele =>
                ele.paymentCorporationId ===
                localStorage.getItem("corporationId")
            );
            this.payInfo = item;
          }
          if (this.payResult) {
            let timer = setInterval(() => {
              this.second--;
            }, 1000);
            this.$once("hook:beforeDestroy", () => {
              clearInterval(timer);
              timer = null;
            });
          }
        }
      });
    },
    // 查看采购订单
    onCheckPurchaseOrder(orderSn) {
      const str = orderSn.substring(0, 2);
      if (str === "YQ") {
        const routeData = this.$router.resolve({
          name: "ForwardPurchaseSubOrder",
          params: { orderSn: orderSn, status: "payment-approval" }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailFactory",
          params: { orderSn: orderSn, type: "detail" }
        });
        window.open(routeData.href, "_blank");
      }
    }
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.tip {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.top {
  margin-top: 10px;
}

.price__item {
  width: 50%;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(164, 173, 179, 0.6);
  box-sizing: border-box;
  padding: 13px 10px;
  margin-top: 20px;
}

.price__item--value {
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin: 0 10%;
}

.right {
  margin-right: 26%;
}

.left {
  margin-left: 26%;
}

.price__item--label {
  min-width: 85px;
}

.commodity__table,
.contract__table {
  margin-top: 20px;
}

.top__back__container {
  border-radius: 4px 0px 0px 4px;
  background-color: rgba(16, 16, 16, 0.4);
  color: white;
  box-sizing: border-box;
  padding: 8px 10px 8px 15px;
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.top__back__container img {
  width: 24px;
  height: 24px;
}

.between {
  display: flex;
  justify-content: space-between;
}

.images img {
  display: none;
}

.pay_images img {
  display: none;
}

.process_img {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-weight: unset;
}

.log__table,
.refunded__table {
  margin-top: 20px;
}

.high__bottom {
  margin-bottom: 150px;
}

.low__bottom {
  margin-bottom: 30px;
}

.examine__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 10px 20px 220px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.examine__form /deep/ .ant-form-item {
  display: flex;
  margin: 0 40px 0 0;
}

.operation {
  margin-top: 20px;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.operation--right {
  margin-right: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tip {
  display: flex;
  align-items: center;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.tip /deep/ .anticon {
  margin-right: 6px;
}

.log__title {
  display: flex;
  align-items: center;
}

.btn--reload {
  margin-left: 50px;
}

.logo__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo__container img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.logo__container span {
  color: rgba(102, 102, 102, 1);
  font-size: 28px;
  font-weight: 500;
}

.pay__num {
  display: flex;
  color: rgba(255, 0, 0, 1);
  font-size: 14px;
  font-weight: bold;
  align-items: flex-end;
  justify-content: center;
}

.pay__num div {
  font-size: 28px;
  transform: translateY(6px);
}

.total__num {
  display: flex;
  justify-content: center;
  color: rgba(16, 16, 16, 1);
  font-weight: 500;
  margin-top: 15px;
}

.pay__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pay__item--logo {
  display: flex;
  align-items: center;
}

.pay__item--logo img {
  width: 38px;
  height: 38px;
  margin-right: 5px;
}

/deep/ .ant-form-item {
  display: flex;
}
</style>
